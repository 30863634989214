.header {
  display:none;
  background-color: $blue;
  border-bottom: 1px dotted $veryLightBlue;
  height: 75px;
  .logo {
      color: $white;
  }
  .languageSwitcher {
    z-index: 1001;
    &.show {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
    .navbar-nav {
      margin-left: auto;

      .dropdown-menu {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
        margin-right: -10px;
      }
    }
  }
  .dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .nav-link {
      color: $white;
      margin-top: -5px;
      &:hover {
        color: $white;
      }
    }
  }
}