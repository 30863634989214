.freeSlotsCalendarContainer {
  .freeSlotsCalendar {
    // background-color: #fff;
    color: $blue;
    // border-radius: 10px;
    // float: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    .calendarHeader {
      display: flex;
      justify-content: space-between;
      font-size: 19px;
      font-weight: 600;
      padding: 25px 25px 15px 25px;
      //float: left;
      width: 100%;
      background-color: #fff;
      border-radius: 10px 10px 0 0;
      span {
        // float:left;
      }
      .prevDate, .nextDate{
        width: 10%;
        font-size: 40px;
        line-height: 24px;
        cursor: pointer;
      }
      .freeSloteDayString {
        cursor: default;
      }
    }
    .react-calendar {
      border:none;
      width: 350px;
      padding: 0 25px 15px;
      width: 100%;
      border-radius: 0 0 10px 10px;
      font-weight: bold;
      background-color: $white;

      .react-calendar__month-view__weekdays__weekday {
        border: none;
        text-align: center;
      }
      .react-calendar__tile {
        border: none;
        padding: 0.5em 0.5em;
      }
      // .react-calendar__navigation {
      //   button {
      //     color: $blue;
      //     font-size: 24px;
      //   }
      // }

      // represents each cell
      button {
        color: $blue;
        font-size: 18px;
        font-weight: bold;
        background-color: $white;
        abbr {
          background-color: transparent;
        }
      }

      // marks all the cells that are disabled
      .disable {
        font-weight: normal;
        color: #CBD0D8;
        background-color: transparent;
      }


      .react-calendar__tile:hover{
        &:not(.disable) {
          border-radius: 50%;
          background-color: #a441dd!important;
          box-shadow: 0px 5px 10px rgba(121, 43, 223, 0.4);
          color: #fff!important;
          transform: translateY(-1px);
        }
      }

      // mark circle around selected day
      .react-calendar__tile--active {
        border-radius: 50%;
        font-weight: bold;
        color: $white !important;
        background-color: $blue;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      }

      // marks the todays date
      .today {
          background-color: #ccc;
          color: $white;
          border-radius: 50%;
      }
    }
  }
}
