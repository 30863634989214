.footer {
  box-shadow: 5px 4px 8px 0 rgba(0, 0, 0, 0.62), 0 6px 20px 0 rgba(0, 0, 0, 0.79);
  background-color: #096fd0c9;
  position: relative;
  padding: 0;
  margin:0;
  padding:0;

  .footerContainer{
    padding: 45px 0 25px 0;
  }

  .rgba-white-light {border: 1px dotted white;}
  .logo {
    font-size:12px;
    img {
      height:auto;
      margin:15px 5px;
      width: 200px;
    }
    // padding: 0 20px;
    // width: 150px;
    // img {
    //   background-color: $blue;
    //   width: 150px;
    //   padding: 0 20px;
    //   margin-top: 15px;
    // }
  }

  .appButtons {
    // display: flex;
    // flex-direction: column;
    // margin-top: 3px;
    // margin-left: auto;
    // a {
    //   height: 25px;
    //   margin-left: auto;
    //   padding: 2px;
    //   img {
    //     height: 100%;
    //   }
    // }
  }

  a {
    color: $white;
    text-decoration: none;
    margin: 0 10px;
  }
}