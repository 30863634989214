.home {
  position: relative;

  h1 {
    margin-bottom: 0;
    font-size: 60px;
    line-height: 60px;

    &.homeBannerBigFont {
      font-size: 70px;
      line-height: 70px;
      span {
        color: $veryLightBlue
      }
    }
  }

  .homeBanner {
    padding-top: 100px;
    z-index: 999;
  }

  .form {
    margin-top: 20px;
    z-index: 1000;

    input {
      font-family: Helvetica, sans-serif !important;
      font-size: 1.3em;
      font-style: normal;
      height: 50px;
      line-height: 30px;
      padding: 10px;
      border-radius: 5px;
      border: none;
      width: 100%;
      &::placeholder {
        color: $placeholderGrey;
      }
    }
  }

  .locationBtnContainer {
    z-index: 999;

    .btlLocation {
      margin-top: 20px;
      background: url('../../common/images/locationPin.svg');
      background-size: 50% 50%;
      background-color: $white;
      background-position: 14px;
      background-repeat: no-repeat;
      border: 0;
      padding: 8px 15px;
      color: white;
      width: 50px;
      height: 50px;
      border-radius: 5px;
      cursor: pointer;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }

  .mobileSnapshot {
    margin-bottom: 350px;
  }
  .textSnapshot {
    margin-top: 120px;
  }

  .appButtons {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: auto;
    position: absolute;
    top: 350px;
    left: 283px;

    a {
      height: 43px;
      margin-left: auto;
      padding: 8px;

      img {
        height: 100%;
      }
    }
  }

  .appSnapshot {
    position: relative;

    .applicationSnapshot {
      position: absolute;
      background-image: url('../../common/images/appSnapshot.png');
      height: 900px;
      width: 100%;
      left: -50px;
      top: -280px;
      background-position: 60%;
    }
  }
  .dropdown-item {
    &.active, &:active {
      color: #fff !important;
      text-decoration: none;
      background-color: #3faacf;
    }

    color: $blue;

    .svg-inline--fa {
      margin: 0 10px 0 0;
    }
  }
  mark {
    &.highlight {
      background-color: transparent;
      padding: 0;
      font-weight: bolder;
      color: $blue;
    }
  }
  .rbt-input-hint {
    display: none !important;
  }

  .selectMedicalSpeciality {
    margin-left: auto;
    margin-bottom: 10px;
    height: 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    &:focus {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $lightBlue;
    }
  }
  .searchBoxImg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .appointerListSearchbox {
    input {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      &:focus {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $lightBlue;
      }
    }
  }
}
