//Colors
$blue:          #2a8ae5;
$lightBlue:     #5ABFEB;
$veryLightBlue: #8BD3F4;
$grey:          #888888;
$darkBlue:      #1D3B65;
$lightGrey:     #F4F3F1;
$darkRed:       #A23A39;
$bleumarin:     #21436C;
$green:         #3FC84C;
$placeholderGrey: #cdcdcd;